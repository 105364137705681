/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap'; // When activated most custom rules are overridden
@import "~@fortawesome/fontawesome-free/css/all.css";

/* You can add global styles to this file, and also import other style files */

:host {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1em;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
  background-color: var(--kaprion-color-grey-5);
}

body {
    font-family: "Roboto", sans-serif;
}

header {
    background-color: var(--kaprion-color-grey-5);
    display: block;
    padding: 15px;
    text-align: center;
    color: var(--kaprion-color-blue-71);
    font-weight: bold;
    margin-left: -8px;
    margin-right: -17px;
}

.footer {
    position: absolute;
    // bottom: 0;
    // left: 0;
    width: 100%;
    .col button.button:first-child {
        margin-left: 1rem;
    }
    //height: 37px;
    border: none;
}

.flex-wrapper {
  margin: auto 0;
  display: flex;
  justify-content: center;
  position: relative;
}

.flex-wrapper-left {
  margin: 0;
  display: flex;
  justify-content: left;
}

.link-box {
  display: block;
  background-color: white;
  color: var(--kaprion-color-blue-71);
  text-align: center;
  font-weight: 700;
  text-decoration: none;
  margin: 0.5em;
  padding: 1em;
  border: 2px solid var(--kaprion-color-blue-71);
  border-radius: 5px;
  transition: background-color 1s, color 1s;
  transition-timing-function: ease-in-out, ease-in-out;
  box-shadow: 5px 5px 5px var(--kaprion-color-grey-20);
}

.link-box:hover {
  background-color: var(--kaprion-color-blue-71);
  color: #fff !important;
}

.link-box a:hover {
  color: #fff !important;
}

.inline {
  margin: auto;
  display: flex;
  justify-content: center;
}

.link-box-white {
  display: block;
  background-color: var(--kaprion-color-white-5);
  color: var(--kaprion-color-blue-71);
  text-align: center;
  font-weight: 700;
  text-decoration: none;
  margin: 0.5em;
  padding: 0.5em;
  border: 1.6px solid var(--kaprion-color-blue-71);
  border-radius: 5px;
}

.link-box-white:hover {
  background-color: var(--kaprion-color-blue-71);
  color: var(--kaprion-color-grey-2);
}

.main-wrapper {
  display: block;
  background-color: var(--kaprion-color-grey-5);
  max-width: 98%;
  align-items: center;
  position: absolute;
  z-index: 0;
}

.card_shadow{
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.09);
}

h1 {
  font-size: 2em;
  font-weight: bold;
}

#loader {
  z-index: 999999;
  position: absolute;
  border: 16px solid #ffffff;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  margin-top: 25%;
  margin-left: 45%;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#loader-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ececec80;
  margin-top: -5%;
  margin-bottom: -5%;
  z-index: 9;
}

h3 {
  font-size: 1.5em;
}
