:root {
    --kaprion-color-blue-71: hsla(211, 59%, 29%, 1.0);
    --kaprion-color-blue-hover-65: hsla(211, 100%, 35%, 1.0);
    --kaprion-color-blue-hover-23: hsla(211, 100%, 77%, 1.0);
    --kaprion-color-blue-glow: hsla(211, 87%, 61%, 1.0);
    --kaprion-color-blue-light: hsl(211, 33%, 84%, 1.0);
    --kaprion-color-red-49: hsla(0, 62%, 51%, 1.0);
    --kaprion-color-red-56: hsla(0, 62%, 44%, 1.0);
    --kaprion-color-grey-2: hsla(0, 0%, 98%, 1.0);
    --kaprion-color-grey-5: hsla(0, 0%, 95%, 1.0);
    --kaprion-color-grey-6: hsla(0, 0%, 94%, 1.0);
    --kaprion-color-grey-20: hsla(0, 0%, 80%, 1.0);
    --kaprion-color-grey-40: hsla(0, 0%, 60%, 1.0);
    --kaprion-color-grey-60: hsla(0, 0%, 40%, 1.0);
    --kaprion-color-neon-green: hsl(70, 62%, 46%, 1.0);
    --kaprion-color-orange: hsla(27, 91%, 54%, 1.0);
    --kaprion-color-skin: hsla(11, 38%, 68%, 1.0);
    --kaprion-color-violet-light: hsla(274, 18%, 92%, 1.0);
}